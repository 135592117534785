import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Icon, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { GiVacuumCleaner, GiEcology } from "react-icons/gi";
import { FaDollarSign } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Shenzhen Starnoon Technology Co., LTD
			</title>
			<meta name={"description"} content={"Welcome to Shenzhen Starnoon Technology Co., LTD, where we redefine the art of cleanliness. Our dedicated team is committed to transforming spaces into immaculate havens. "} />
			<meta property={"og:title"} content={"Home | Shenzhen Starnoon Technology Co., LTD"} />
			<meta property={"og:description"} content={"Welcome to Shenzhen Starnoon Technology Co., LTD, where we redefine the art of cleanliness. Our dedicated team is committed to transforming spaces into immaculate havens. "} />
			<meta property={"og:image"} content={"https://fraiveckonis.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fraiveckonis.com/img/shenzhen.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fraiveckonis.com/img/shenzhen.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fraiveckonis.com/img/shenzhen.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fraiveckonis.com/img/shenzhen.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://fraiveckonis.com/img/shenzhen.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://fraiveckonis.com/img/shenzhen.jpeg"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenu" />
		</Components.Header>
		<Section padding="80px 0 80px 0" quarkly-title="Cards-7">
			<Override slot="SectionContent" width="95%" min-width="95%" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				lg-flex-direction="column"
				grid-gap="24px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="30%"
					lg-width="auto"
					lg-margin="0px 0px 50px 0px"
				>
					<Text
						margin="0px 0px 8px 0px"
						text-align="left"
						color="#ec7524"
						font="--base"
						letter-spacing="2px"
						text-transform="uppercase"
					>
						Cleaning
					</Text>
					<Text
						margin="0px 0px 30px 0px"
						font="normal 500 42px/1.2 --fontFamily-sans"
						text-align="left"
						md-margin="0px 0px 40px 0px"
						sm-font="normal 600 42px/1.2 --fontFamily-sans"
						color="--darkL1"
					>
						Shenzhen Starnoon Technology Co., LTD{" "}
					</Text>
					<Text margin="0px 0px 40px 0px" font="normal 500 21px/1.2 --fontFamily-sans" text-align="left" color="--darkL1">
						Welcome to Shenzhen Starnoon Technology Co., LTD, where we redefine the art of cleanliness. Our dedicated team is committed to transforming spaces into immaculate havens. With attention to detail and a passion for pristine results, we take cleaning to a whole new level.
					</Text>
					<Button
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						padding="15px 44px 15px 44px"
						background="#ec7524"
						border-radius="4px"
						color="--light"
						href="/contact"
						type="link"
						text-decoration-line="initial"
						sm-display="inline-block"
						sm-align-items="center"
						sm-text-align="center"
					>
						View more
					</Button>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="36px 24px"
					lg-grid-template-columns="repeat(2, 1fr)"
					sm-grid-template-columns="1fr"
					md-grid-template-columns="1fr"
					md-grid-gap="36px 24px"
					width="70%"
					lg-width="auto"
				>
					<Box
						min-width="100px"
						min-height="100px"
						border-color="--color-lightD2"
						display="flex"
						flex-direction="column"
						justify-content="space-between"
						border-width={0}
					>
						<Image src="https://fraiveckonis.com/img/1.jpg" display="block" margin="0px 0px 30px 0px" />
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 15px 0px" font="normal 500 28px/1.2 --fontFamily-sans" color="#133957">
								Residential Cleaning
							</Text>
						</Box>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width={0}
						border-style="solid"
						border-color="--color-lightD2"
						display="flex"
						flex-direction="column"
						justify-content="space-between"
					>
						<Image src="https://fraiveckonis.com/img/2.jpg" display="block" margin="0px 0px 30px 0px" />
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 15px 0px" font="normal 500 28px/1.2 --fontFamily-sans" color="#133957">
								Commercial Cleaning
							</Text>
						</Box>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width={0}
						border-style="solid"
						border-color="--color-lightD2"
						display="flex"
						flex-direction="column"
						justify-content="space-between"
					>
						<Image src="https://fraiveckonis.com/img/3.jpg" display="block" margin="0px 0px 30px 0px" />
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 15px 0px" font="normal 500 28px/1.2 --fontFamily-sans" color="#133957">
								Specialized Services
							</Text>
						</Box>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width={0}
						border-style="solid"
						border-color="--color-lightD2"
						display="flex"
						flex-direction="column"
						justify-content="space-between"
					>
						<Image src="https://fraiveckonis.com/img/4.jpg" display="block" margin="0px 0px 30px 0px" />
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 15px 0px" font="normal 500 28px/1.2 --fontFamily-sans" color="#133957">
								Event Cleanup
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="88px 0 88px 0"
			sm-padding="60px 0 60px 0"
			box-shadow="--m"
			background="--color-light"
			md-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-25"
		>
			<Override slot="SectionContent" max-width="1220px" sm-min-width="280px" />
			<Box
				width="100%"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				margin="0px 0px 64px 0px"
				display="flex"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 28px/1.2 --fontFamily-sans"
					text-align="center"
					md-font="--headline3"
				>
					Why Choose Shenzhen Starnoon Technology Co., LTD?
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="24px"
				lg-grid-gap="16px"
				md-grid-template-columns="1fr"
			>
				<Box
					display="flex"
					padding="46px 46px 47px 46px"
					background="#FFFFFF"
					flex-direction="column"
					justify-content="flex-start"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						margin="0px 0px 30px 0px"
						color="--orange"
						size="64px"
						category="gi"
						icon={GiVacuumCleaner}
						lg-font="48px sans-serif"
					/>
					<Text font="--lead" margin="0px 0px 18px 0px" color="--darkL2" text-align="center">
						<Strong letter-spacing="0.5px" text-transform="uppercase" text-align="center">
							Bespoke Cleaning Solutions
						</Strong>
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						text-align="center"
					>
						Tailored cleaning plans to suit your unique needs, ensuring every nook and cranny receives the attention it deserves.
					</Text>
				</Box>
				<Box
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					background="#FFFFFF"
					display="flex"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						icon={GiEcology}
						margin="0px 0px 30px 0px"
						color="--orange"
						size="64px"
						category="gi"
						lg-font="48px sans-serif"
					/>
					<Text color="--darkL2" font="--lead" margin="0px 0px 18px 0px" text-align="center">
						<Strong letter-spacing="0.5px" text-transform="uppercase" text-align="center">
							Eco-Friendly Products
						</Strong>
					</Text>
					<Text
						font="--base"
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						text-align="center"
					>
						We prioritize your well-being and the environment, using eco-friendly cleaning products that are safe and effective.
					</Text>
				</Box>
				<Box
					display="flex"
					background="#FFFFFF"
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						size="64px"
						category="fa"
						icon={FaDollarSign}
						margin="0px 0px 30px 0px"
						color="--orange"
						lg-font="48px sans-serif"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--lead" text-align="center">
						<Strong letter-spacing="0.5px" text-transform="uppercase">
							Transparent Pricing
						</Strong>
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						text-align="center"
					>
						Our pricing is straightforward, with no hidden costs. Experience excellence without breaking the bank.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});